export default function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else if (window.addEventListener) {
      // window.addEventListener('load', fn);
      window.addEventListener('DOMContentLoaded', fn);
  } else {
    window.attachEvent('onreadystatechange', function() {
      if (document.readyState !== 'loading')
        fn();
      });
  }
}