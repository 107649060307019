
const createEndpointUrl = (endpoint) => {
    return process.env.REACT_APP_BASE_SERVER_URL + endpoint
}

const handleResponse = (response) => {
    if (response.ok) {
        return response.json()
    }
  
    if (response.status >= 300 && response.status < 500) {
        return response.json()
    }
  
    return Promise.reject(response.statusText)
}

const handleError = (errorMessage) => {
    let message = ''
    if (errorMessage instanceof Error) {
        message = errorMessage.message
    } else {
        message = errorMessage
    }
  
    return {
        metadata: {
            success: false,
            message: errorMessage,
            errors: [ { attribute: 'general', messages: [message] } ]
        }
    }
}

export const authenticateUser = async (params) => {
    const url = createEndpointUrl('/api/v1.0/sso/auth/')
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(handleResponse)
    .catch(handleError)
}