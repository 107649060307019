import { authenticateUser } from './services'
import * as rpc from './rpc'
import Cookies from 'js-cookie'

const COOKIE_KEY = 'makerkit_auth_token'

export const setupRpcServices = () => {
  rpc.setRpcWindow(window.parent, '*')
  rpc.on('auth', auth)
  rpc.on('getAuthToken', getAuthToken)
  rpc.on('setAuthToken', setAuthToken)
  rpc.on('clearAuthToken', clearAuthToken)
}

/**
 * Clear all cookies on this domain
 */
const clearAuthToken = () => {
  clearAllCookies()
  return {
    metadata: { success: true }
  }
}

/**
 * Set the auth token
 * 
 * @param {object} data 
 */
const setAuthToken = (data) => {
  clearAllCookies()
  Cookies.set(COOKIE_KEY, data.authToken, { expires: 365 })
  return {
    metadata: { success: true }
  }
}

/**
 * Get stored auth token, if it exists
 */
const getAuthToken = () => {
  const token = Cookies.get(COOKIE_KEY)
  if (!token) {
    return {
      metadata: { success: false }
    }
  } else {
    return {
      metadata: { success: true },
      data: {
        authToken: token
      }
    }
  }
}

/**
 * Performs SSO authentication
 * 
 * @param {object} data 
 */
const auth = async (data) => {
  // check if we've already got the auth token
  const tokenInCache = isTokenInCache(data)
  if (tokenInCache) {
    return {
      metadata: { success: true, cached: true },
      data: {
        authToken: Cookies.get(COOKIE_KEY)
      }
    }
  }

  // if not in cookie cache, lookup details using our API
  const response = await authenticateUser(data)
  if (response.metadata.success) {
    Cookies.set(COOKIE_KEY, response.data.authToken, { expires: 365 })
    Cookies.set(tokenCacheId(data), true, { expires: 365 })
    return {
      metadata: { success: true },
      data: { authToken: response.data.authToken }
    }
  }
  return response
}

/**
 * Checks if the auth token is in the cached for the request
 * 
 * @param {object} data 
 */
const isTokenInCache = (data) => {
  const cached = Cookies.get(tokenCacheId(data))
  if (cached) {
    return true
  }
  return false
}

/**
 * Generate a key value for cache lookup
 */
const tokenCacheId = ({ userHash, appId }) => {
  return `token_id_${userHash}_${appId}`
}

/**
 * Clear all domain cookies
 */
const clearAllCookies = () => {
  const cookies = Cookies.get()
  for (const k in cookies) {
    Cookies.remove(k)
  }
}