// postmessage rpc client/server

const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
const eventer = window[eventMethod];
const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

const generateID = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
}

const clientName = 'makerkit-rpc'
let _rpcWindow = null;
let _origin = null

export const isPromise = (o) => {
  return o.then !== undefined
}

export const listen = (callback) => {
  eventer(messageEvent, callback)
}

export const setRpcWindow = (win, origin) => {
  _rpcWindow = win
  _origin = origin
}

export const send = (func, message, callback) => {
  const payload = {
    message: message,
    id: generateID(),
    func: func,
    client: clientName,
  }

  const responseHandler = (event) => {
    if (event.data.client !== clientName) {
      return
    }
    if (event.data.id !== payload.id) {
      return
    }
    if (event.data.func !== func) {
      return
    }
    window.removeEventListener(messageEvent, responseHandler)
    callback(event.data.message)
  }

  listen(responseHandler)
  _rpcWindow.postMessage(payload, _origin)
}

export const on = (func, callback) => {
  const requestHandler = (event) => {
    if (event.data.client !== clientName || event.data.func !== func) {
      return
    }

    let payload = {
        id: event.data.id,
        client: clientName,
        func: func,
    }

    const onSuccess = (response) => {
      _rpcWindow.postMessage({
        ...payload,
        message: response,
      }, _origin)
    }

    const onError = (err) => {
      console.log(err)
    }

    const response = callback(event.data.message)

    if (isPromise(response)) {
      Promise.resolve(response).then(onSuccess).catch(onError)
    } else {
      onSuccess(response)
    }
  }

  listen(requestHandler)
}
